.formInput{
    /* display: flex;
    align-items: start; */
    display: flex;
    flex-direction: column;
    width: 100%;
}

.inputfield{
    padding:10px 10px;
    /* margin:10px; */
    width: 100%;
    margin: 10px 0px;
    border: 1px solid gray;
    border-radius: 8px;
}


label{
    font-size: 12px;
    color: gray;
}

.errormessge{
    font-size: 12px;
    padding: 3px;
    color: red;
    display: none;

}

input:invalid[focused="true"]{
    border: 1px solid red;
}

input:invalid[focused="true"] ~ span{
    display: block;
}