.registrationcss {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  
  background-size: cover;
  background-position: center;
}
@media only screen and (min-width:350px) {
 .customform{
  border-radius: 10px;
  max-width: 450px;
 }

}
.customform {
 
  width: 90%;
  height: min-content;
  align-items: center;
  /* background-color: white; */
  padding: 20px 20px;


  
}

