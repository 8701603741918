.loginbutton{
    width: 100%;
    height: 50px;
    padding: 10px;
    border: none;
    background-color: blue;
    color: white;
    border-radius: 5px;
    font-weight: bold;
    font-size: 18px;
    cursor: pointer;
    margin-top: 15px;
    margin-bottom: 15px;
}