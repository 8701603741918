
@import "react-toastify/dist/ReactToastify.css";
body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
}

.exchangeStyle,
.blockChainStyle {
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 8px;
}

.cryptotaxt {
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 8px;
align-items: center;
}

.blockchainpopup{
  /* max-width: 767px; */
  min-width: 280px;

  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 8px;
}
 

.thead th:nth-child(2) {
  text-align: left;
  padding-left: 15px;
}

.no-arrow::-ms-expand,
.no-arrow::-webkit-scrollbar {
  display: none !important;
}
.border-clr {
  border-color: transparent;
}


@media (max-width: 767px) {
  .delete-modal {
    position: fixed;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
  }
}

@media (min-width: 901px) {
  .small-screen-logo {
    display: none;
  }
}
@media (max-width: 900px) {
  .small-screen-logo {
    display: block;
  }
}
@media (max-width: 575px) {
  .small-screen-modal {
    width: 100% !important;
  }
}

@media screen and (max-width: 991px) {
  .chart-res {
    /* flex-wrap: wrap; */
    flex-direction: column;
  }
  .doughnut {
    width: 290px;
   
  }
  .doughnut canvas {
    height: 280px !important;
    width: 280px !important;

  }
}
@media screen and (min-width: 576px) {
  .doughnutWidth {
    width: 300px;
  }
  .barWidth {
    width: 540px;
  }
}
@media screen and (min-width: 992px) {
  .bar {
    height: 280px;
  }
  .bar canvas {
    /* height: 270px; */
    height: 238px !important; 
    width: 540px;
  }
  /* .doughnut canvas {
        width: 230px !important;
        height: 230px;
    } */
  .doughnut {
    width: 300px;
    height: 280px;
  }
  .doughnut canvas {
    max-width: 240px;
    max-height: 240px;
  }
}
@media screen and (max-width: 767px) {
  .doughnut {
    width: 300px;
  }

  .bar canvas {
    width: 100% !important;
    min-height: 150px;
  }
}
@media screen and (max-width: 556px) {

  .editinput{
    display: flex;
    flex-direction: column;
  }

  .inputfieldwidth{
    width: 100%;
    /* margin-bottom: 5px */
  }
  .margins{
    margin-top: 10px;
  }

}

 @media screen and (min-width: 557px) {
 .place{
  display: flex;
 }
 .placeinput{
  width: 100%;
 }
 .inputfieldwidth{
  margin-right: 5px
 }
}





@tailwind base;
@tailwind components;
@tailwind utilities;

